frappe.ui.form.AssignToDialog.prototype.get_fields = function () {
	let me = this;
	return [
		{
			label: __("Assign to me"),
			fieldtype: "Check",
			fieldname: "assign_to_me",
			default: 0,
			onchange: () => me.assign_to_me(),
		},
		{
			fieldtype: "MultiSelectPills",
			fieldname: "assign_to",
			label: __("Assign To"),
			reqd: true,
			// overriding this get_data function to filter multiselect field based on linked suppliers
			get_data: function (txt) {
				return me.doctype == "Supplier"
					? new Promise((resolve) => {
							frappe.call({
								method: "erpnext_custom_app.override_functions.get_linked_supplier_email",
								args: {
									doctype: me.doctype,
									docname: me.frm.docname,
								},
								callback: function (response) {
									resolve(response.message);
								},
							});
					  })
					: frappe.db.get_link_options("User", txt, {
							user_type: "System User",
							enabled: 1,
					  });
			},
		},
		{
			fieldtype: "Section Break",
		},
		{
			label: __("Complete By"),
			fieldtype: "Date",
			fieldname: "date",
		},
		{
			fieldtype: "Column Break",
		},
		{
			label: __("Priority"),
			fieldtype: "Select",
			fieldname: "priority",
			options: [
				{
					value: "Low",
					label: __("Low"),
				},
				{
					value: "Medium",
					label: __("Medium"),
				},
				{
					value: "High",
					label: __("High"),
				},
			],
			// Pick up priority from the source document, if it exists and is available in ToDo
			default: ["Low", "Medium", "High"].includes(
				me.frm && me.frm.doc.priority ? me.frm.doc.priority : "Medium"
			),
		},
		{
			fieldtype: "Section Break",
		},
		{
			label: __("Comment"),
			fieldtype: "Small Text",
			fieldname: "description",
		},
	];
};
