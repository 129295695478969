// Overridding core function not to show some field in data import for Trade Term Contracts Doctype

frappe.require("data_import_tools.bundle.js", () => {
	frappe.data_import.DataExporter.prototype.get_multicheck_options = function (
		doctype,
		child_fieldname = null
	) {
		if (!this.column_map) {
			this.column_map = get_columns_for_picker(this.doctype);
		}

		let autoname_field = null;
		let meta = frappe.get_meta(this.doctype); // Corrected 'doctype' to 'this.doctype'
		if (meta.autoname && meta.autoname.startsWith("field:")) {
			let fieldname = meta.autoname.slice("field:".length);
			autoname_field = frappe.meta.get_field(this.doctype, fieldname); // Corrected 'doctype' to 'this.doctype'
		}

		let fields = child_fieldname
			? this.column_map[child_fieldname]
			: this.column_map[this.doctype]; // Corrected 'doctype' to 'this.doctype'

		let is_field_mandatory = (df) => {
			if (df.reqd && this.exporting_for == "Insert New Records") {
				return true;
			}
			if (autoname_field && df.fieldname == autoname_field.fieldname) {
				return true;
			}
			if (df.fieldname === "name") {
				return true;
			}
			return false;
		};

		return fields
			.filter((df) => {
				if (autoname_field && df.fieldname === "name") {
					return false;
				}
				return true;
			})
			.map((df) => {
				return {
					label: __(df.label, null, df.parent),
					value: df.fieldname,
					danger: is_field_mandatory(df),
					checked: false,
					description: `${df.fieldname} ${df.reqd ? __("(Mandatory)") : ""}`,
				};
			});
	};
});

function get_columns_for_picker(doctype) {
	console.log(doctype, "doctype");

	let out = {};

	const exportable_fields = (df) => {
		let keep = true;
		if (frappe.model.no_value_type.includes(df.fieldtype)) {
			keep = false;
		}
		if (["lft", "rgt"].includes(df.fieldname)) {
			keep = false;
		}
		if (df.is_virtual) {
			keep = false;
		}
		// Exclude vendor_signature and chewy_signature
		const excluded_fields = ["vendor_signature", "chewy_signature"];
		if (doctype == "Trade Term Contract" && excluded_fields.includes(df.fieldname)) {
			keep = false;
		}
		return keep;
	};

	// parent
	let doctype_fields = frappe.meta.get_docfields(doctype).filter(exportable_fields);

	out[doctype] = [
		{
			label: __("ID"),
			fieldname: "name",
			fieldtype: "Data",
			reqd: 1,
		},
	].concat(doctype_fields);

	// children
	const table_fields = frappe.meta.get_table_fields(doctype);
	table_fields.forEach((df) => {
		const cdt = df.options;
		const child_table_fields = frappe.meta.get_docfields(cdt).filter(exportable_fields);

		out[df.fieldname] = [
			{
				label: __("ID"),
				fieldname: "name",
				fieldtype: "Data",
				reqd: 1,
			},
		].concat(child_table_fields);
	});

	return out;
}
