setTimeout(function(){
    frappe.ui.form.ControlInput.prototype.set_description = function(description) {
        // Only proceed if the field type is "Check"
        if (this.df.fieldtype !== "Check") {
            return;
        }
        
        // Update the description if provided
        if (description !== undefined) {
            this.df.description = description;
        }
    
        // Return if the description has not changed or if only_input is true
        if (this.only_input || this.df.description === this._description) {
            return;
        }
        
        // Update the description display
        if (this.df.description) {
            this.$wrapper.find(".help-box").html(__(this.df.description));
        } else {
            this.set_empty_description();
        }
    
        // Store the updated description
        this._description = this.df.description;
    }
    
},200)



setTimeout(function(){
    frappe.ui.form.ControlInput.prototype.set_required = function () {
        this.label_area && $(this.label_area).toggleClass("reqd", Boolean(this.df.reqd));
        // Tooltip logic
        if (!$(this.label_area).find('.tooltip-icon').length && this.df.description && this.df.fieldtype!="Check") {
            let tooltipIcon = $(`<span class="tooltip-icon" style="margin-left: 5px; margin-right: 5px; cursor: pointer;" data-original-title="${__(this.df.description)}">
                <img style="width: 10px" src='/assets/erpnext_custom_app/images/info.svg'/>
            </span>`);
        
            // Append the tooltip icon beside the label (form view)
            if (this.label_area) {
                $(this.label_area).append(tooltipIcon);
            }
        
            // Initialize the tooltip
            tooltipIcon.tooltip({
                placement: 'right',
                trigger: 'hover'
            });
        
            // Optional: Add click-to-copy functionality
            tooltipIcon.on("click", (e) => {
                let text = $(e.target).closest('.tooltip-icon').attr('data-original-title');
                frappe.utils.copy_to_clipboard(text);
            });
        }
    }
	
    
},200)

document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		setTimeout(() => {
			$('.grid-heading-row .col.grid-static-col').unbind("click");
		}, 1000);
	});
});