import "./theme-switcher.js"

// Set the width to full screen by default
localStorage.container_fullwidth = true;
$(document.body).addClass("full-width");

//add red border to all  mandatory fields
$(document).ready(function() {
    setTimeout(function() {
        $('.frappe-control').each(function() {
            var label = $(this).find('.control-label.reqd');
            if (label.length > 0) {
                var inputElement = $(this).find('input');

                if (inputElement.val().trim() === '') {
                    inputElement.css('border', '1px solid #ff6666');
                }
                inputElement.blur('input', function() {

                    if (inputElement.val().trim() === '') {
                        inputElement.css('border', '1px solid #ff6666');
                    } else {
                        inputElement.css('border', 'none');
                    }
                });
            }
        });
    }, 1000);
});
  

console.log("INJECTED FILE")