frappe.widget.widget_factory.number_card.prototype.get_settings = function (type) {
	this.filters = this.get_filters();
	const settings_map = {
		Custom: {
			method: this.card_doc.method,
			args: {
				filters: this.filters,
				card_filters: this.card_filters || "",
			},
			get_number: (res) => this.get_number_for_custom_card(res),
		},
		Report: {
			method: "frappe.desk.query_report.run",
			args: {
				report_name: this.card_doc.report_name,
				filters: this.filters,
				ignore_prepared_report: 1,
			},
			get_number: (res) => this.get_number_for_report_card(res),
		},
		"Document Type": {
			method: "frappe.desk.doctype.number_card.number_card.get_result",
			args: {
				doc: this.card_doc,
				filters: this.filters,
				card_filters: this.card_filters || "",
			},
			get_number: (res) => this.get_number_for_doctype_card(res),
		},
	};
	return settings_map[type];
};

frappe.widget.WidgetGroup.prototype.add_widget = function (widget) {
	let widget_object = frappe.widget.make_widget({
		...widget,
		widget_type: this.type,
		container: this.body,
		card_filters: this.card_filters || "",
		height: this.height || null,
		options: {
			...this.options,
			on_delete: (name) => this.on_delete(name),
		},
	});

	this.widgets_list.push(widget_object);
	this.widgets_dict[widget.name] = widget_object;

	return widget_object;
};

frappe.widget.widget_factory.number_card.prototype.set_card_actions = function (actions) {
	if (this.name != "Section Break") {
		this.card_actions = $(`<div class="card-actions dropdown pull-right">
				<a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				...
				</a>
				<ul class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
					${actions
						.map(
							(action) =>
								`<li class="dropdown-item">
									<a data-action="${action.action}">${action.label}</a>
								</li>`
						)
						.join("")}
				</ul>
			</div>`);

		this.card_actions.find("a[data-action]").each((i, o) => {
			const action = o.dataset.action;
			$(o).click(actions.find((a) => a.action === action));
		});

		this.action_area.html(this.card_actions);
	}
};

frappe.widget.widget_factory.number_card.prototype.make_widget = function () {
	if (this.name == "Section Break") {
		this.widget = $(
			`<div class="dashboard-card-section-break"><b>${this.custom_label || ""}</b></div>`
		);
	} else {
		this.widget = $(`<div class="widget" data-widget-name="${this.name ? this.name : ""}">
			<div class="widget-head">
				<div class="widget-label">
					<div class="widget-title"></div>
					<div class="widget-subtitle"></div>
				</div>
				<div class="widget-control"></div>
			</div>
			<div class="widget-body"></div>
			<div class="widget-footer"></div>
		</div>`);

		this.title_field = this.widget.find(".widget-title");
		this.subtitle_field = this.widget.find(".widget-subtitle");
		this.body = this.widget.find(".widget-body");
		this.action_area = this.widget.find(".widget-control");
		this.head = this.widget.find(".widget-head");
		this.footer = this.widget.find(".widget-footer");
		this.refresh();
	}
};

frappe.widget.widget_factory.number_card.prototype.refresh = function () {
	if (this.name != "Section Break") {
		this.set_title();
		this.set_actions();
		this.set_body();
		this.setup_events();
		this.set_footer();
	}
};
